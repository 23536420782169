import React from "react";
import { sparkle } from "../assets/whatsapp";

// function Chat({ pp, contact, msg, time, unreadMsgs, active, onClick }) {
//   return (
//     // Chat container
//     <div onClick={onClick}
//       className={`flex justify-between items-center cursor-pointer w-full h-[85px] px-3 hover:bg-[#202d33] ${
//         active ? "bg-[#202d33]" : ""
//       }`}
//     >
//       {/* Profile picture */}
//       {pp && <img
//         src={pp}
//         alt="profile_picture"
//         className="rounded-full w-[50px] mr-5"
//       />}

//       {/* Info container */}
//       <div className="flex justify-between border-t border-neutral-700 w-full h-100 py-3">
//         {/* Contact name and message */}
//         <div className="flex flex-col justify-between text-white">
//           {/* Contact name */}
//           <h1 className="font-medium mb-1">{contact}</h1>

//           {/* Message */}
//           <p className={`text-[8px] ${!unreadMsgs ? "text-neutral-400" : ""} line-clamp-1 text-ellipsis max-w-[60vw] font-semibold`}>
//             {msg}
//           </p>
//         </div>

//         {/* Time and number of messages*/}
//         <div className="flex flex-col justify-between items-end h-100 text-[12px]">
//           {/* Time */}
//           <p className="text-emerald-500 min-w-[55px]">{time}</p>

//           {/* Number of messages */}
//           {
//               <img className=" w-4" alt="" src={sparkle} />
//            }
//         </div>
//       </div>
//     </div>
//   );
// }

function Chat({ pp, contact, msg, time, unreadMsgs, active, onClick }) {
  return (
    // Chat container
    <div
      className={`flex flex-col justify-between items-center w-full p-2 ${
        active ? "bg-[#202d33]" : ""
      }`}
    >
      {/* Profile picture */}
      {pp && (
        <img
          src={pp}
          alt="profile_picture"
          className="rounded-full w-[50px] mr-5"
        />
      )}

      {/* Info container */}
      <div className="flex flex-col space-y-1 justify-between text-white items-center border border-neutral-700 w-full h-full px-3 py-2 rounded-md">
        {/* Time */}
        <p className="text-emerald-500 min-w-[55px] text-[8px]">{time}</p>

        {/* Contact name */}
        <h1 className="font-medium mb-1 text-center">{contact}</h1>

        {/* Message */}
        {/* <p
          className={`text-[8px] ${
            !unreadMsgs ? "text-neutral-400" : ""
          } line-clamp-1 text-ellipsis max-w-[60vw] font-semibold`}
        >
          {msg}
        </p> */}

        <img className=" w-4" alt="" src={sparkle} />

        <div className="flex space-x-4 py-2 w-full justify-around items-center">
          <button className="border text-sm px-4 py-2 rounded bg-white text-green-900 flex-1"
          onClick={()=>onClick('analyze')}>Analyze</button>
          <button className="border text-sm px-4 py-2 rounded bg-white text-green-900 flex-1"
          onClick={()=>onClick('chat')}>Chat</button>
        </div>
      </div>
    </div>
  );
}

export default Chat;
