import React, { useState, useEffect } from "react";
import Chat from "./Chat";
import { chatsData } from "../data/whatsapp";
import { ImFolderDownload } from "react-icons/im";
import { zeroPad } from "../helpers";
import { getMessage } from "../firebaseConfig";
import moment from "moment";

function Chats({ chats, setSelected, selected, search }) {
  
  const [sel, setSel] = useState(selected);
  function timeConverter(UNIX_timestamp) {
    var a = new Date(UNIX_timestamp);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var monthNum = zeroPad(a.getMonth() + 1, 2);
    var date = zeroPad(a.getDate(), 2);
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + "/" + monthNum + "/" + year; // + '-' + hour + ':' + min + ':' + sec ;
    return time;
  }
  return (
    // Chats main container
    <div className="flex flex-row flex-wrap overflow-y-scroll h-100">
      {/* Chats */}
      {chats?.files
        ?.filter((chat) =>
          chat.title
            .replace("WhatsApp Chat with ", "")
            .split(".")[0]
            .toLowerCase()
            .includes(search.toLowerCase())
        )
        ?.map((chat, i) => {

          const arry = chats.content
            ?.find((obj) => obj.id === chat.id)
            ?.chat?.data;
          const log = arry?.[arry.length - 1]?.content

          const time = timeConverter(Date.parse(chat.createdDate));
          return (
            <Chat
              key={i}
              pp={null}
              contact={
                chat.title.replace("WhatsApp Chat with ", "").split(".")[0]
              }
              msg={log}
              time={`from ${moment(chat.createdDate).fromNow()}`}
              unreadMsgs={""}
              active={chat.id === sel}
              onClick={(move) => {
                setSel(chat.id);
                setSelected(chat.id, move);
              }}
            />
          );
        })}
    </div>
  );
}

export default Chats;
