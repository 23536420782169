import React from 'react'
import { Link } from 'react-router-dom'

const TnCs=()=> {
  return (
    <div className='flex flex-row w-full justify-center items-center text-white space-x-8'>
    <Link to={'/terms'} className='text-xs font-light underline'>Terms of use</Link>
        <Link to={'/policy'} className='text-xs font-light underline'>Privacy Policy</Link>
        </div>
  )
}

export default TnCs