import { useAuthState } from "react-firebase-hooks/auth";
import WhatsApp from "./pages/WhatsApp";
import { Paytime, SignIn, auth, collections, db, getUser } from "./firebaseConfig";
import { Fragment, useEffect, useRef, useState } from "react";
import LoadingScreen from "./components/LoadingScreen";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, RouterProvider, Routes, createBrowserRouter } from "react-router-dom";
import ChatDetail from "./components/ChatDetail";
import PolicyPage from "./components/PolicyPage";
import TermsPage from "./components/TermsPage";
import { listReply } from "./replier";
import { doc } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";
import { Dialog, Transition } from "@headlessui/react";

const router = createBrowserRouter([
  { path: "*", element: <Root /> }
])

export default function App() {
  return (
    <RouterProvider router={router} />
  )
}

function Root() {
  const [user, user_loading, err] = useAuthState(auth)
  const [loading, setLoading] = useState(true)
  const [payup, setPayUp] = useState(false)
  const [progress, setProgress] = useState(0);
  const [chats, setChats] = useState(null);
  let refDiv = useRef(null)

  useEffect(() => {
    const id = setTimeout(() => {
      if (progress >= 100) setLoading(false);
      else {
        const increment = Math.floor(Math.random() * (10 + 1)) + 7;
        setProgress(progress + increment);
      }
    }, 300);

    return () => clearTimeout(id);
  }, [progress])

  const [app, unit_loading, _] = useDocument(doc(db, collections.CONSTS, "app"), { snapshotListenOptions: { includeMetadataChanges: true } })
  const [value, token_loading, error] = useDocument(user ? doc(db, collections.USERS, user.uid) : null, { snapshotListenOptions: { includeMetadataChanges: true } })

  useEffect(() => {

    const init = async () => {
      const user = await getUser()
      const chats = await listReply(user.token, app?.data().chatlimit);
      setChats(chats);
    }
    app && user && init()
  }, [app, user])

  return (
    <div className="w-screen h-full overflow-hidden">
      <div className="flex justify-start whatsapp-bp:justify-center items-center bg-[#111a21] h-full">
        {loading || user_loading ?
          <Routes>
            <Route path="*" element={<LoadingScreen progress={progress} />} />
          </Routes>
          :
          <Routes>
            {user ?
              <>
                <Route path="/" element={<WhatsApp chats={chats} token={value?.data()?.token ?? ''} />} />
                <Route path="/chat">
                <Route path=":id"
                  element={
                    <ChatDetail value={value} chats={chats} limit={app?.data().chatlimit} replies={value?.data()?.replies ?? 0} setPayUp={setPayUp} />
                  }
                />
                </Route>
              </>
              :
              <>
              <Route path="/terms" element={<TermsPage />} />
              <Route path="/policy" element={<PolicyPage />} />
              <Route path="*" element={<SignIn />} />
              </>
              }
          </Routes>

        }
      </div>

      {user &&
        <Transition appear show={payup} as={Fragment}>
          <Dialog
            initialFocus={refDiv} as="div" className="relative z-10" onClose={() => setPayUp(false)}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white align-middle shadow-xl transition-all">
                    <Dialog.Title className="leading-6 text-sm text-gray-900 text-center bg-green-100 p-6">
                      <b>Hey, glad you're having fun</b>
                      <br />
                      <br />
                      You're low on reply tokens, top up with only <b>$25</b> to continue your chat with the double
                    </Dialog.Title>
                    <div className="flex flex-col items-center w-full justify-center bg-white py-4">
                      {/* <span className="text-black font-bold text-sm text-center w-1/2 pb-4">{`Only $10!`}</span> */}
                      <Paytime data={{ uid: user.uid, cost: 25, days: 100 }} setPayUp={setPayUp} />
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      }
    </div>
  )
}

