import React, { useEffect, useState } from "react";
import Chats from "./Chats";
import RoundedBtn from "./Common/RoundedBtn";
import { MdPeopleAlt } from "react-icons/md";
import { TbCircleDashed } from "react-icons/tb";
import { BsFillChatLeftTextFill } from "react-icons/bs";
import { HiDotsVertical } from "react-icons/hi";
import { BiFilter } from "react-icons/bi";
import { logo } from "../assets/whatsapp";
import {
  SignIn,
  SignOut,
  auth,
  getMessage,
  signInWithGoogle,
} from "../firebaseConfig";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import Instructions from "./Instructions";
import useDrivePicker from "react-google-drive-picker";
import { parseChatToJson } from "../replier";

function LeftMenu({ chats, selected, token }) {
  let navigate = useNavigate()
  const setSelected = (i, move) => {
    getMessage(i, move).then((hist) => {
      
      navigate(`chat/${i}`, { state: {move:move,hist: move==='chat'?hist?.chat ?? []:hist?.analysis ??[]} });
    });
  };

  const [search, setFilter] = useState("");

  const [openPicker, authResponse] = useDrivePicker();
  // const customViewsArray = [new google.picker.DocsView()]; // custom view
  const handleOpenPicker = () => {
    openPicker({
      // clientId: "681624062565-qranta3giotqbg8c213c18itivpaehup.apps.googleusercontent.com",
      developerKey: "AIzaSyDp2iCHT4GLdHWduoudmiJouWJ_I2U2R08",
      viewId: "DOCS",
      token: token, 
      showUploadView: false,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: false,
      // customViews: customViewsArray, // custom view
      callbackFunction: (data) => {
        if (data.action === "cancel") {
          console.log("User clicked cancel/close button");
        }
        console.log(data);
        const headers = {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json'
        }
        data?.docs?.map(async (file) => {
        const contentUrl = `https://www.googleapis.com/drive/v3/files/${file.id}?alt=media`;
        const contentResponse = await fetch(contentUrl, { headers });
        const txt = await contentResponse.text();
        console.log({id:file.id, chat: await parseChatToJson(txt, file.name?.replace('WhatsApp Chat with ','').split('.')[0], 300, file.id)})
      })
      },
    });
  }

  return (
    // LeftMenu container
    <div className="bg-[#111a21] min-w-[340px] md:max-w-[500px] md:w-100 w-screen h-full max-h-full">
      <div className="flex flex-col border-neutral-700 w-full h-full">
        {/* Profile nav */}
        <div className="flex justify-between items-center bg-[#202d33] h-[60px] p-3">
          {/* Profile picture */}
          <button onClick={() => {}}>
            <img
              src={logo}
              alt="profile_picture"
              className="rounded-full w-[40px] cursor-pointer border hover:border-white"
            />
          </button>

          {/* Profile nav buttons */}
          <div className="flex flex-col justify-center flex-1 px-4 items-start">
            <span className="font-semibold text-white text-[16px]">
              RepliesGPT
            </span>
            <span className="text-white text-[7px] font-thin">
              {auth.currentUser?.email?.toLowerCase()}
            </span>
            {/*
            <RoundedBtn icon={<MdPeopleAlt />} />
            <RoundedBtn icon={<TbCircleDashed />} />
            <RoundedBtn icon={<BsFillChatLeftTextFill/>} />
            <RoundedBtn icon={<HiDotsVertical />} />
            
          */}
          </div>
          <SignOut />
        </div>

        {/* Search and filter */}
        <div className="flex justify-between items-center h-[60px] p-2">
          {/* Search input */}
          <input
            type="text"
            placeholder="Search for chat"
            className="rounded-lg bg-[#202d33] text-[#8796a1] text-sm font-light outline-none px-4 py-2 w-[400px] h-[35px] placeholder:text-[#8796a1] placeholder:text-sm placeholder:font-light"
            onChange={(e) => setFilter(e.target.value)}
          />

          {/* Filter button */}
          {/* <button
          className={`text-2xl m-2 p-1 rounded-full ${
            filter
              ? "bg-emerald-500 text-white rounded-full hover:bg-emerald-700"
              : "text-[#8796a1] hover:bg-[#3c454c]"
          }`}
          onClick={() => setFilter(!filter)}
        >
          <BiFilter />
        </button> */}
        </div>
        {/* <div>
          <button onClick={() => handleOpenPicker()}>Open Picker</button>
        </div> */}
        {
          /* Chats */
          chats ? (
            chats.files?.length > 0 ? (
              <Chats
                chats={chats}
                setSelected={setSelected}
                selected={selected}
                search={search}
              />
            ) : (
              <div className="h-full flex flex-col justify-center items-center px-8 space-y-4">
                <span className=" text-white text-center text-sm">
                  No WhatsApp Chats Found in Drive
                </span>
                <Instructions />
              </div>
            )
          ) : (
            <div className="h-full flex flex-col justify-center items-center">
              <ReactLoading className="" type="bubbles" color="green" />
            </div>
          )
        }
      </div>
    </div>
  );
}

export default LeftMenu;
