import React, { useState, useEffect } from "react";
import LeftMenu from "../components/LeftMenu";
import ChatDetail from "../components/ChatDetail";
import { SignIn, auth, collections, db } from "../firebaseConfig";
import { useDocument } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";
import { logo } from "../assets/whatsapp";
import { useNavigate } from "react-router-dom";

function WhatsApp({ chats, token }) {
  return <LeftMenu chats={chats} token={token} />;
}

export default WhatsApp;
