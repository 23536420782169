import { getAnalytics } from "firebase/analytics";
import { initializeApp } from 'firebase/app'
import React, { useState, useEffect } from 'react';
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth'
import { collection, query, where, getFirestore, doc, setDoc, getDoc, getDocs, Timestamp, updateDoc, orderBy, limit, addDoc, FieldValue, arrayUnion, increment } from 'firebase/firestore';
import { useCollection, useDocument, useCollectionData } from 'react-firebase-hooks/firestore';

import { addDays } from "./helpers";
import { logo } from "./assets/whatsapp";
import Instructions from "./components/Instructions";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import TnCs from "./components/TnCs";

const firebaseConfig = {
  apiKey: "AIzaSyBwgYqZ9WUA3f_igvi1YMa3mM8JAF3KwPw",
  authDomain: "repliesgpt.com",
  databaseURL: "https://firenode-86abd.firebaseio.com",
  projectId: "firenode-86abd",
  storageBucket: "firenode-86abd.appspot.com",
  messagingSenderId: "681624062565",
  appId: "1:681624062565:web:97e19dce822c61c363948c",
  measurementId: "G-CXS4HH1HPF"
};

export const collections = {
  TRANSACTIONS: "Transactions",
  TILLS: "Tills",
  PHONES: "Phones",
  ACCOUNTS: "Accounts",
  CHATS: "AIChats",
  CONSTS: "Constants",
  VIDEOS: "Videos",
  USERS: "Users"
}

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);

export function SignIn() {

  const SignInText = () => {
    return (
      <span className="flex flex-col my-2 mx-4 justify-center items-center space-y-4 text-white font-medium text-[16px]">
        Get started!
      </span>
    )
  }

  const SignInButton = () => {
    return (

      <div className="flex justify-center flex-wrap items-center">
        <div className="card max-w-[80%] bg-blue-500 rounded-xl">
          <button onClick={signInWithGoogle} type="button" className="card-front-image card-image text-[12px] rounded-xl bg-blue-500 shadow shadow-white border border-x-white">
            {<SignInText key={'foreground'} />
            }
          </button>
          <div className="card-faders w-full">
            {Array(8).fill().map((_, idx) =>

              <button key={idx} onClick={signInWithGoogle} type="button" className="card-fader card-image text-[12px] rounded-xl bg-blue-500 shadow">
                {<SignInText />
                }
              </button>
            )
            }
          </div>
        </div>
      </div>
    )
  }
  return (

    <div className="bg-[#111a21] min-w-[340px] md:max-w-[500px] md:w-100 w-screen h-full max-h-full flex flex-col">
      {/* Profile nav */}
      <div className="flex justify-between items-center bg-[#202d33] h-[60px] p-3">
        {/* logo picture */}
        <img
          src={logo}
          alt="profile_picture"
          className="rounded-full w-[40px] cursor-pointer border hover:border-white"
        />

        {/* Profile nav buttons */}
        <div className="flex justify-start items-center flex-1 px-4">
          <span className="font-bold text-white text-xl">
            RepliesGPT
          </span>
        </div>
      </div>
      <div className="flex-1 flex flex-col px-8 py-4 space-y-8 overflow-y-scroll justify-around h-full">
        <div class="font-sans">

          <h1 className="text-5xl md:text-6xl flex flex-col font-extrabold tracking-tighter my-8" data-aos="zoom-y-out">
            <span className="bg-clip-text px-1 text-transparent bg-gradient-to-r from-blue-500 to-teal-400">
              Miss Me? Chat with My AI Double!
            </span>
          </h1>

          <main class="flex flex-col space-y-16">
            <section class="flex flex-col space-y-2">
              <span class="text-xl font-medium text-gray-300">Ever miss those late-night conversations?</span>
              <div class="w-full flex flex-col space-y-8 justify-center">
                <span class="text-gray-200 text-sm">We all cherish those connections. RepliesGPT scans for exported WhatsApp chats in your Google Drive account and uses AI to bring back the essence of your favorite conversations!</span>
                <ul class="list-disc space-y-2 px-4 text-xs text-gray-200">
                  <li>Stay connected with loved ones who are far away.</li>
                  <li>Preserve memories with cherished friends or family.</li>
                  <li>Document thoughts, experiences, and reflections.</li>
                  <li>Have fun and reminisce about old times.</li>
                </ul>
              </div>
            </section>
            {/* <Instructions /> */}
            <SignInButton />
            <section class="text-center mt-8">
              <p class="text-gray-700">**Disclaimer:** This AI is based on a specific chat and cannot replicate the full range of a person's personality. However, it can offer a unique way to revisit past conversations and bring back cherished memories.</p>
            </section>
          </main>
        </div>
        {/* <div className="text-white flex flex-col space-y-8 items-center">

          <p className="text-lg text-center">What are some things you can do with an AI Replicate?</p>

          <ul className="flex flex-col space-y-8 text-xs m-4">
            <li><strong>Have Entertainment Discussions:</strong>
              Discuss movies, books, music, and other forms of entertainment, creating a shared space for cultural exploration and recommendations.</li>

            <li><strong>Seek Advice and Guidance:</strong>
              Seek advice or guidance from the replicate, allowing you to receive insights based on the replicated individual's knowledge and perspective.</li>

            <li><strong>Learning and Education:</strong>
              Use the replicate as a learning partner, discussing educational topics, asking questions, and receiving explanations in a conversational format.</li>

            <li><strong>Storytelling Sessions:</strong>
              Collaborate with the replicate to co-create stories or narratives, allowing you to continue a shared imaginary world or explore creative writing.</li>

            <li><strong>Personal Growth Conversations:</strong>
              Explore self-improvement topics, receive motivation, and set goals with the guidance and support of the replicate.</li>

            <li><strong>Digital Journaling:</strong>
              Use the replicate as a digital journal companion, documenting thoughts, experiences, and reflections, creating a personal and ongoing narrative.</li>

            <li><strong>Historical Conversations:</strong>
              Engage in historical conversations, discussing past events, personal experiences, or the user's history to create a richer understanding of their own story.</li>

            <li><strong>Memory Recall Games:</strong>
              Play memory recall games where the replicate helps you remember details from the past, enhancing cognitive abilities and fostering nostalgia.</li>

            <li><strong>Travel Through Time:</strong>
              Discuss different time periods, cultural shifts, or technological advancements, providing you with a unique perspective on history.</li>

            <li><strong>Philosophical Conversations:</strong>
              Engage in philosophical discussions on life, existence, and purpose, allowing you to explore deep and meaningful concepts.</li>
          </ul>
          <p className="text-lg text-center my-4">... and so much more!</p>
        </div>
        <SignInButton /> */
          <TnCs />}
      </div>
    </div>
  )
}

export const Paytime = ({ data, setPayUp }) => {
  const { uid, cost, days } = data
  const PP_CLIENT_ID = process.env.REACT_APP_PAY_PAL_CLIENT_ID
  console.log(data)
  const createOrder = (data, actions) => {
    return fetch("https://us-central1-rabble-87dd5.cloudfunctions.net/authApp/create-order", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uid: uid, units: days, cost: cost }),
    }).then(res => {
      if (res.ok) return res.json()
      return res.json().then(json => Promise.reject(json))
    }).then(({ id }) => {
      return id
    }).catch(e => {
      console.error(e.error)
    })
  }

  const onApprove = (data, actions) => {
    return actions.order.capture().then((details) => {
      const name = details.payer.name.given_name
      setPayUp(false)
      alert(`Transaction completed by ${name}`)
    })
  }
  return (
    <div className={'w-full px-8'}>

      <PayPalScriptProvider options={{ "client-id": PP_CLIENT_ID }}>
        <PayPalButtons //disabled={replies < limit} 
          forceReRender={[data]}
          style={{
            style: {
              layout: 'horizontal',
              color: 'white',
              shape: 'rect',
              label: 'checkout',
              tagline: false
            }
          }}
          createOrder={createOrder}
          onApprove={onApprove} />
      </PayPalScriptProvider>
    </div>

  )
}
export function SignOut() {
  return (
    <button className=" hover:bg-red-400 text-white text-[12px] px-4 py-2 rounded-full " onClick={logout}>Sign out</button>
  );
}

export const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  provider.addScope('https://www.googleapis.com/auth/drive');
  signInWithPopup(auth, provider)
    .then(async (result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      //check if user exists

      const user_doc = doc(db, collections.USERS, user.uid);
      const docSnap = await getDoc(user_doc);

      if (docSnap.exists()) {
        await updateDoc(user_doc, { token: token })
        // doc.data() will be undefined in this case
        console.log("User exists!");
      } else {
        // save user
        const docData = {
          created: Timestamp.fromDate(new Date()),
          uid: user.uid,
          name: user.displayName,
          email: user.email,
          token: token,
          channelName: user.displayName,
          photo: user.photoURL,
          replies: 10,
          latest_video: '',
          clipboard: false,
          service: "repliesgpt"
        }

        await setDoc(user_doc, docData)
        // const channelName = await testReply(token)
        // await updateDoc(user_doc, { channelName: channelName ? channelName : user.displayName })
      }
    }).catch((error) => {
      console.log(error);
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });
}

export const getUser = async () => {

  const user_doc = doc(db, collections.USERS, auth.currentUser.uid);
  const docSnap = await getDoc(user_doc);
  const d = docSnap.data()

  return {
    created: d.created,
    uid: d.uid,
    name: d.name,
    email: d.email,
    token: d.token,
    photo: d.photo,
    replies: d.replies,
    service: d.service
  }

}

export const updateUsage = async (uid, created, debit, clipping, setIsClipping) => {

  const days = debit === 7000 ? 28 : debit === 2000 ? 7 : 1
  const exp = addDays(created.toDate(), days)
  const expDate = exp.toLocaleDateString("en-GB")
  const expTime = exp.toLocaleTimeString("en-GB")
  const today = new Date()
  const expStr = `${expDate} ${expTime}`
  console.log("expires", expStr)
  if (today > exp) {
    console.log("expired")
    setIsClipping(true)
    if (clipping) {
      const user_doc = doc(db, collections.USERS, uid);
      await updateDoc(user_doc, { clipboard: false })
    }
  } else {
    console.log("valid")
  }
  return expStr
}
export const saveMessage = async (id, docData, assistant, cookie, move) => {

  const chat_doc = doc(db, collections.CHATS, id);
  const docSnap = await getDoc(chat_doc);
  const toks = move === 'chat' ? 1 : 5

  if (docSnap.exists()) {
    const data = move === 'chat' ? {
      updated: Timestamp.fromDate(new Date()),
      chat: arrayUnion(docData),
      cookie: cookie
    } : {
      updated: Timestamp.fromDate(new Date()),
      analysis: arrayUnion(docData),
      cookie: cookie
    }
    await updateDoc(chat_doc, data)
  } else {
    const data = move === 'chat' ? {
      uid: auth.currentUser.uid,
      assistant: assistant,
      updated: Timestamp.fromDate(new Date()),
      created: Timestamp.fromDate(new Date()),
      chat: arrayUnion(docData),
      cookie: cookie
    } : {
      uid: auth.currentUser.uid,
      assistant: assistant,
      updated: Timestamp.fromDate(new Date()),
      created: Timestamp.fromDate(new Date()),
      analysis: arrayUnion(docData),
      cookie: cookie
    }
    await setDoc(chat_doc, data)
  }

  if (docData.role === 'user' || move !== 'chat') {
    // console.log('less')
    await updateDoc(doc(db, collections.USERS, auth.currentUser.uid), {
      replies: increment(-toks)
    })
  }
}
export const getMessage = async (id, move) => {
  // console.log('getting chat')
  const user_doc = doc(db, collections.CHATS, id)
  const docSnap = await getDoc(user_doc)

  if (docSnap.exists()) {
    const d = docSnap.data()

    return {
      uid: d.uid,
      assistant: d.assistant,
      created: d.created,
      chat: d.chat,
      analysis: d.analysis
    }
  } else {
    return null
  }

}

export const logout = () => {
  signOut(auth).then(() => {
    // Sign-out successful.
  }).catch((error) => {
    // An error happened.
  });
}
