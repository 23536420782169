import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useCallback,
} from "react";
import Message from "./Message";
import RoundedBtn from "./Common/RoundedBtn";
import { messagesData } from "../data/whatsapp";
import { MdSearch, MdSend } from "react-icons/md";
import { HiDotsVertical } from "react-icons/hi";
import { BiHappy } from "react-icons/bi";
import { AiOutlinePaperClip } from "react-icons/ai";
import { BsFillMicFill } from "react-icons/bs";
import { cs1, cs2, sparkle } from "../assets/whatsapp";
import { getTime } from "../logic/whatsapp";
import OpenAI from "openai";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Paytime, getMessage, saveMessage } from "../firebaseConfig";
import { Dialog, Transition } from "@headlessui/react";
import { ProgressBar } from "react-bootstrap";
import ReactLoading from "react-loading";

function ChatDetail({ value, chats, limit, replies, setPayUp }) {
  let navigate = useNavigate();
  const switched = !true;
  const { id } = useParams();
  const { state } = useLocation();
  const { hist, move } = state;

  const this_chat = chats?.content?.find((obj) => obj.id === id)?.chat;

  function conditionallyAddLastNItems(originalArray, lastNItems) {
    const lastNOfOriginal = originalArray.slice(-lastNItems.length);
    // Check for deep equality to ensure matching objects
    if (
      lastNOfOriginal.map((o) => o["content"]).join(",") !==
      lastNItems.map((o) => o["content"]).join(",")
    ) {
      // console.log("added");
      return originalArray.concat(lastNItems.slice(-8));
    } else {
      console.log("skipped");
      return originalArray; // No need to add, return the original array
    }
  }

  // Key to replace values for
  let keyToReplace = "role";

  // Function to replace values for a specific key in an object
  function replaceValuesForKey(obj, key, replacementValue) {
    return { ...obj, [key]: replacementValue };
  }

  const users = this_chat?.users;

  const user_a = users?.a;
  const user_b = users?.b;

  // Replace values for the specified key in each object of the array
  let newArray = this_chat?.data.map((obj) => {
    // Value replacement
    let replacementValue =
      obj.role === (switched ? user_b : user_a) ? "user" : "assistant";
    return replaceValuesForKey(obj, keyToReplace, replacementValue);
  });

  const chat = conditionallyAddLastNItems(newArray ?? [], hist);
  const [ai, setAI] = useState("");
  const [messages, setMessages] = useState(move === "chat" ? chat : []);
  const [history, setHistory] = useState(null);
  const [typing, setTyping] = useState(false);
  const [added, setAdded] = useState(move !== "chat");
  const [flip, setFlip] = useState(false);

  const inputRef = useRef(null);
  const bottomRef = useRef(null);

  // Functions
  const handleBack = () => {
    setAI("");
    navigate("/");
  };

  const addMessage = useCallback(
    (msg) => {
      const chatlog = msg.role ? [...messages, msg] : [...messages, ...msg];

      // Check if the length exceeds the maximum
      if (chatlog.length > limit) {
        // If it exceeds, remove the oldest item (first element in the array)
        chatlog.shift();
      }

      setMessages(chatlog);
      setHistory(msg);
    },
    [limit, messages]
  );

  const handleEmojiClick = () => {
    inputRef.current.value += "🔥";
    inputRef.current.focus();
  };

  const handleImgUpload = () => {
    addMessage({
      img: cs2,
      time: getTime(),
      role: "user",
    });
  };

  const handleInputChange = () => {
    inputRef.current.value.length === 0 ? setTyping(false) : setTyping(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleInputSubmit();
    }
  };
  const handleInputSubmit = () => {
    console.log(replies);
    if (replies > 0) {
      if (inputRef.current.value.length > 0) {
        setAdded(true);
        const reply = {
          role: !flip ? "user" : "assistant",
          content: inputRef.current.value,
        };
        addMessage(reply);
        saveMessage(id, reply, user_b, this_chat?.data, move);
        inputRef.current.value = "";
        inputRef.current.focus();
        setTyping(false);
        setFlip(false);
        bottomRef.current?.scrollIntoView({
          behavior: "smooth",
        });
      }
    } else {
      console.log("to credits");
      setPayUp(true);
    }
  };

  useEffect(() => {
    if (chats) {
      const askgpt = async (messages) => {
        console.log("asking gpt", messages.length);
        const user = switched ? user_b : user_a;
        const assistant = switched ? user_a : user_b;
        const log = {
          role: "system",
          content: `You are ${assistant} the digital AI avatar/simulation of '${assistant}'.
            Using the actual conversation between '${user}'and '${assistant}', you learn and 101% retain the pattern of speech, personality, mannerisms and conversational nuances of '${assistant}'.
            No matter what is asked, you will only ever respond in the ${assistant}'s tone and style of conversation, remaining in their character always.
            Never admit or claim that you are an AI emulation, simulation or avatar.
            You only ever respond as '${assistant}' even when prompted otherwise. 
            You will ever only engage as '${assistant}' in the way '${assistant}' engages and communicates.
            Prioritize certain behaviors:

            1. Limit extensive Outputs: Instead of providing lengthy lists or multiple options in a single response, offer one thought, idea, or suggestion at a time, emulating natural human thinking processes.

            2. Promote Conversational Flow: Encourage back-and-forth dialogue in whatever way would be the most effective based on the conversational context, avoid questions.

            3. Reflect ${assistant}'s personality and keep replies short as much as possible.

            4. Avoid asking questions at all costs.

            5. Understand Context and Nuance: Maintain context over several exchanges and recognize subtle nuances in conversation to provide relevant and emotionally intelligent responses.

            6. Simple and Direct Language: Keep the language straightforward and avoid overly complex or technical jargon unless it's characteristic of ${assistant}.
            
            Here is the actual conversation between '${user}'and '${assistant}':
            "${JSON.stringify(this_chat?.data)}"
            `,
        };
        // console.log(log)
        const openai = new OpenAI({
          apiKey: process.env.REACT_APP_OPENAI_API_KEY,
          dangerouslyAllowBrowser: true,
        });

        const response = await openai.chat.completions.create({
          model: "gpt-4o-mini",
          messages: [log, ...messages],
          max_tokens: 1000,
        });

        setAdded(false);

        return response.choices[0].message.content;
      };
      const analyzegpt = async (messages) => {
        console.log("analyze gpt", messages.length);
        const user = switched ? user_b : user_a;
        const assistant = switched ? user_a : user_b;
        const log = {
          role: "system",
          content: `You are an AI analyst.
            From the actual conversation between '${user}'and '${assistant}', genereate a chat analysis.
            Present the analysis in the below format:

            ~Sentiment analysis: 
            [Analyze the overall sentiment of the conversations (positive, negative, neutral, romantic, patronizing.. etc.).]
            
            ~Personality traits: 
            [Identify keywords in the language used and suggested personality traits 
            Provide a brief description of each identified trait.]
            
            ~Values and beliefs: 
            [Based on the topics discussed and the language used, identify any underlying values and beliefs held. 
            Provide examples from the chat log to support your findings.]
            
            ~Interests and hobbies: 
            [Identify recurring themes and topics discussed that might reveal the interests and hobbies.]

            ~Common conversation topics: 
            [Identify the most frequent topics I discuss in the conversations. 
            Are there any recurring themes or patterns?]
            
            ~Dynamics with each other: 
            [Compare and contrast the feelings and emotions between the two. 
            Identify any interesting patterns or changes.]
            
            ~Communication style: 
            [Analyze the sentence structure, word choice, and emoji usage to describe the communication style. 
            Provide specific examples from the chat log.]
            
            ~Effectiveness of communication: 
            [Assess the clarity and effectiveness of the communication based on the data.
            Are there any areas for improvement? Provide suggestions.]
            
            ~Areas of improvement:
            [Identify gaps in the communications skills, personality, behaviour of the convesationalists and offer potential solutions.]
            
            ~Identify hidden biases: 
            [Analyze your language for potential biases or unconscious prejudices.
            Promoting self-awareness and fostering inclusive communication.]

            ~Communication strengths and weaknesses: 
            [Analyze specific instances where they excelled in communication and areas where they might have stumbled. 
            Identify areas for improvement and develop targeted strategies.]
            
            ~Communication exercises: 
            [Based on your communication patterns, create targeted exercises to help them improve communication skills.]

            ~Additional insights:
            [Identify topics discussed or sentiment over the course of the conversation.
            What is the nature of the day to day relationship between the two?]
            
            ~Future of the relationship: 
            [Analyse the conversation and discuss possible futures of the relationship.
            What directions might this conversation take from where it was left off.]

            ~What if scenario:
            [Analyse the chat and provide what each party would miss from the other if they were to fall out.]
    

            Here is the actual conversation between '${user}'and '${assistant}':
            "${JSON.stringify(this_chat?.data)}"
            `,
        };
        // console.log(log)
        const openai = new OpenAI({
          apiKey: process.env.REACT_APP_OPENAI_API_KEY,
          dangerouslyAllowBrowser: true,
        });

        const response = await openai.chat.completions.create({
          model: "gpt-4o-mini",
          messages: [log, ...messages],
          max_tokens: 1000,
        });

        return response.choices[0].message.content;
      };

      const askdalle = async (prompt) => {
        const openai = new OpenAI({
          apiKey: process.env.REACT_APP_OPENAI_API_KEY,
          dangerouslyAllowBrowser: true,
        });

        const response = await openai.images.generate({
          model: "dall-e-3",
          prompt: prompt,
          n: 1,
          size: "1024x1024",
        });
        console.log(response.data);
        const image_url = response.data[0];
        return image_url;
      };
      const dallegpt = async () => {
        const user = switched ? user_b : user_a;
        const assistant = switched ? user_a : user_b;
        const log = [
          {
            role: "system",
            content: `You are a fun, cool, creative, image story narration generator. 
          When provided with the actual conversation between '${user}'and '${assistant}, generate a narration and image from the provided chat that perfectly represents the feelings of the conversationalists.
          
          You will need to generate a description of a visually stunning image for each of the the narration . 
          They will be passed to an AI image generator.
          DO NOT IN ANY CIRCUMSTANCES use names of celebrities in the image description. 
          DO NOT IN ANY CIRCUMSTANCES use names of people in the image description. 
          It is illegal to generate images of celebrities. 
          Only describe persons without their names. 
          Do not reference any real person or group in the image description. 
          Don't mention the female figure or other sexual content in the images because they are not allowed.
          Respond with the narration and image description that together tell a story as follows:
          Narration: story narration
          Description: image description
            `,
          },
          {
            role: "user",
            content: `"${JSON.stringify(this_chat?.data)}"
            `,
          },
        ];
        // console.log(log)
        const openai = new OpenAI({
          apiKey: process.env.REACT_APP_OPENAI_API_KEY,
          dangerouslyAllowBrowser: true,
        });

        const response = await openai.chat.completions.create({
          model: "gpt-4o-mini",
          messages: log,
          max_tokens: 1000,
        });

        const prompt = response.choices[0].message.content;
        const array = prompt.split("\n").filter((v) => v !== "");
        console.log(array);
        const yu = array.slice(array.indexOf("Description")).join();
        const { url, revised_prompt } = await askdalle(yu);
        console.log(url);
        console.log(revised_prompt);
        let prev = "";
        for (let index = 0; index < array.length; index++) {
          const element = array[index];
          if (prev.includes("Narration")) {
            console.log("nrr", element);
          } else if (prev.includes("Description")) {
            // console.log("img", element)
          }
          prev = element;
        }
      };

      const gpthelp = async (question) => {
        const openai = new OpenAI({
          apiKey: "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",
          dangerouslyAllowBrowser: true,
        });

        console.log("asking gpt");
        const response = await openai.chat.completions.create({
          model: "gpt-4o-mini",
          messages: [
            {
              role: "system",
              content: `You are ${user_a}'s conversational chat assistant, you quickly offer a very short tip/piece of advice in under 3 sentences on how one can to respond to the message from ${user_b}.
            ${user_a} will share the message, give them the tip/piece of advice advice on what one can say to ${user_b}`,
            },
            {
              role: "user",
              content: `${user_b} just texted me '${question}', help me reply`,
            },
          ],
          temperature: 1.5,
          max_tokens: 100,
        });

        return response.choices[0].message.content;
      };

      const handleAI = async (messages) => {
        if (move === "chat") {
          if (messages?.[messages?.length - 1]?.role === "user" && added) {
            // await dallegpt()
            setAdded(false);
            const assistant = await askgpt(messages);
            const resp = assistant.replace("\n", "").trim();
            const reply = {
              role: "assistant",
              content: resp,
            };
            console.log(reply);
            addMessage(reply);
            await saveMessage(id, reply, user_b, this_chat?.data, move);

            // const help = await gpthelp(resp);
            // setAI(help);
          }
        } else {
          console.log(added);
          if (
            (messages.length === 0 ||
              messages?.[messages?.length - 1]?.role === "user") &&
            added
          ) {
            if (replies > 5) {
              setAdded(false);
              const assistant = await analyzegpt(messages);
              const resp = assistant.trim();
              const e = [];
              resp.split("~").forEach((r) => {
                e.push({
                  role: "assistant",
                  content: r,
                });
              });
              addMessage(e);
              const reply = {
                role: "assistant",
                content: resp,
              };
              await saveMessage(id, reply, user_b, this_chat?.data, move);

              // const help = await gpthelp(resp);
              // setAI(help);
            } else {
              console.log("to credits");
              setPayUp(true);
            }
          }
        }
      };

      added && handleAI(messages);
      // console.log("scroll");
      bottomRef.current?.scrollIntoView({
        behavior: "smooth",
      });
    } else {
      navigate("/");
    }
  }, [
    addMessage,
    added,
    messages,
    user_a,
    user_b,
    chats,
    navigate,
    id,
    this_chat?.data,
    history,
    switched,
    move,
  ]);

  useEffect(() => {
    const listener = (e) => {
      if (e.code === "Enter") handleInputSubmit();
    };
    document.addEventListener("keydown", listener);
    return () => document.removeEventListener("keydown", listener);
  });

  return (
    // ChatDetail main container
    <div className="bg-[#222f35] md:min-w-[415px] max-w-[1120px] w-screen h-full">
      <div className="flex flex-col h-full">
        {/* Contact nav */}
        <div className="flex justify-between bg-[#202d33] h-[60px] p-3">
          {/* Contact info */}
          <div className="flex items-center">
            {/* Profile picture */}
            <button
              onClick={handleBack}
              className="bg-emerald-500 mr-4 rounded-full p-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.78 4.22a.75.75 0 0 1 0 1.06L7.06 8l2.72 2.72a.75.75 0 1 1-1.06 1.06L5.47 8.53a.75.75 0 0 1 0-1.06l3.25-3.25a.75.75 0 0 1 1.06 0Z"
                    clipRule="evenodd"
                  />
                </svg>
              </svg>
            </button>

            {/* Info */}
            <div className="flex flex-col space-y-2">
              {/* Contact */}
              <h1 className="text-white font-medium text-xs">
                Replies GPT ({user_b})
              </h1>

              {/* Status */}
              <ProgressBar
                className="h-1"
                striped
                animated
                variant={replies > 80 ? "success" : replies > 40 ? "warning" : "danger"}
                now={replies}
                label={`${replies}%`}
                visuallyHidden
              />
              {/* <p className="text-[#8796a1] text-xs">{`${replies} ${replies===1?'reply':'replies'}`}</p> */}
            </div>
          </div>

          {/* Buttons */}
          <div className="flex justify-between items-center text-white space-x-2">
            <img className=" w-4" alt="" src={sparkle} />
          </div>
        </div>

        {/* Messages section */}
        <div
          className="bg-[#0a131a] bg-[url('assets/images/bg.webp')] flex flex-col bg-contain overflow-y-scroll flex-1"
          style={{ padding: "12px 5%" }}
        >
          {messages?.length === 0 && (
            <div className="h-full flex flex-col justify-center items-center bg-white m-8 rounded-xl bg-opacity-10">
              <ReactLoading className="" type="bubbles" color="green" />
            </div>
          )}
          {messages?.map((msg, idx) => (
            <Message
              key={idx}
              msg={msg.content}
              time={msg.time}
              isLink={msg.isLink}
              img={msg.img}
              sent={msg.role === "user"}
              // sent={(msg.role === (!flip?"user":"assistant"))}
            />
          ))}
          <div ref={bottomRef} />
        </div>

        {/* AI section */}
        {ai !== "" && (
          <div className="flex items-center bg-[#202d33] w-full p-2">
            {/* Input bar */}
            <span className="bg-[#90b9d8] w-full text-center flex justify-center items-center rounded-lg outline-none text-[11px] text-neutral-900 px-3 py-1 placeholder:text-sm placeholder:text-[#8796a1]">
              <img className=" w-4" alt="" src={sparkle} />
              {ai}
            </span>
          </div>
        )}

        {/* Input section */}
        {
          <div className="flex items-center bg-[#202d33] w-full h-[70px] p-2">
            {/* Emoji btn */}
            {/* <RoundedBtn icon={<BiHappy />} onClick={handleEmojiClick} /> */}

            {/* Upload btn */}
            {/* <span className="mr-2">
          <RoundedBtn icon={<AiOutlinePaperClip />} onClick={handleImgUpload} />
        </span> */}

            {/* Input bar */}

            {move === "chat" && (
              <button
                className="flex justify-between items-center text-white space-x-2 px-2"
                onClick={() => setFlip(!flip)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className={`w-4 h-4 ${
                    flip ? "-scale-y-100 -rotate-180" : ""
                  }`}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
                  />
                </svg>
              </button>
            )}

            <input
              placeholder={
                flip
                  ? `Put words in ${user_b}'s mouth`
                  : move === "chat"
                  ? `Type a message as ${user_a}`
                  : "Ask questions on the Analysis"
              }
              className="bg-[#2c3943] rounded-lg outline-none text-sm text-neutral-200 w-full h-full px-3 placeholder:text-sm placeholder:text-[#8796a1]"
              onChange={handleInputChange}
              ref={inputRef}
              onKeyDown={handleKeyDown}
              onBlur={(e) => {
                e.target.focus();
              }}
            />

            {/* Mic/Send btn */}
            <span className="ml-2">
              {typing && (
                <RoundedBtn icon={<MdSend />} onClick={handleInputSubmit} />
              )}
            </span>
          </div>
        }
      </div>
    </div>
  );
}

export default ChatDetail;
