import React from 'react'

const Instructions = () => {
  return (
    <div className="flex flex-col justify-center items-center px-8 py-4 space-y-3 border">
      <span className=" text-emerald-600 text-center text-[11px]">
        On Android, go to WhatsApp, select and open a chat you've shared a lot in.
      </span>
      <span className=" text-emerald-600 text-center text-[11px]">{`Tap on the three dot (⫶) icon top right`}</span>
      <span className=" text-emerald-600 text-center text-[11px]">{`Tap 'More'`}</span>
      <span className=" text-emerald-600 text-center text-[11px]">{`Tap 'Export Chat'`}</span>
      <span className=" text-emerald-600 text-center text-[11px]">{`Tap 'without media'`}</span>
      <span className=" text-emerald-600 text-center text-[11px]">{`Then send to your 'DRIVE/GOOGLE DRIVE'`}</span>
      <span className=" text-emerald-600 text-center text-[11px]">{`Come back and get started!`}</span>
    </div>
  )
}

export default Instructions