import { getMessage, getUser, logout } from "./firebaseConfig"

// Function to get the last N elements from the deque
function getLastNElements(n, chatlog) {
  // Check if the requested number is greater than the array length
  const startIndex = Math.max(0, chatlog.length - n);

  // Use array slicing to get the last N elements
  return chatlog.slice(startIndex);
}

export const parseChatToJson=async(text, b, lim, id) =>{
  // Initialize an empty array to store the JSON objects
  const jsonArray = [];

  // Split the text into lines
  const lines = text?.trim()?.split("\n");

  // Initialize variables to store information during iteration
  let currentEntry = {};
  let a = ''

  // Iterate through each line
  for (const line of lines) {
    // Try to split the line into date, time, user, and message
    if (
      !line.includes("Tap to learn more.") &&
      !line.includes("<Media omitted>")
    ) {
      try {
        const [dateStr, rest] = line.split(" - ", 2);
        const [date, time] = dateStr.split(", ", 2);
        const dateTimeStr = `${date?.trim()} ${time.trim()}`;
        // Parse the date and time
        const dateTime = new Date(dateTimeStr);
        // Split the user and message
        const [user, messagePart] = rest.split(": ");

        // Ensure that currentEntry is always defined before updating it

        currentEntry = {
          // 'datetime': dateTime.toISOString(),
          role: user,// === b ?"assistant"  : "user",
          content: messagePart,
        };
        if(user !== b){a=user}

        // Append the current entry to the JSON array
        jsonArray.push(currentEntry);
      } catch (error) {
        // If the line doesn't split into the expected parts, consider it a continuation of the previous message
        currentEntry["content"] += " " + line?.trim();
        continue;
      }
    }
  }

  const hist = []//(await getMessage(id))?.chat??[];
  const r = getLastNElements(lim, jsonArray.concat(hist)).filter((m)=> m.content)
  // console.log(r)
  return {data: r, users:{a:a, b:b}};
}

export const listReply = async (auth, limit, recurr = true) => {
  !recurr && console.log("retrying..")
  const headers = {
    'Authorization': `Bearer ${auth}`,
    'Accept': 'application/json'
  }

  try {
    const call = await fetch('https://www.googleapis.com/drive/v2/files', {
      method: 'GET',
      headers: headers
    })

    const response = await call.json()
    if (response.error) {
      const skips = [400, 403]
      console.log("error", response.error.code)
      console.log("post_err", response.error.message)
      if(!(response.error.code in skips)){
        if(recurr){
          const user = await getUser()
          listReply(user.token, limit, false)
          return
        }

        logout()
      }else{
        console.log('no logout')
      }
    } else {
      // Filter files with names containing "whatsapp"
      const toReadFiles = response.items.filter((file) => file.title.includes('WhatsApp') && file.title.includes('.txt'))

      // Build promises to fetch individual file content
      const contentPromises = toReadFiles.map(async (file) => {
        const contentUrl = `https://www.googleapis.com/drive/v3/files/${file.id}?alt=media`;
        const contentResponse = await fetch(contentUrl, { headers });
        const txt = await contentResponse.text();
        return {id:file.id, chat: await parseChatToJson(txt, file.title?.replace('WhatsApp Chat with ','').split('.')[0], limit, file.id)}
      });

      // Wait for all content to be fetched and return it
      const fileContents = await Promise.all(contentPromises);
      
      return { files: toReadFiles, content: fileContents };
    }
  } catch (err) {
    console.log('catch', err);
  }
  return null
}

export const postReply = async (resp, auth, count, setPrompting) => {

  const reply = resp.reply//`${resp.reply}\n~ with ❤️ from repliesgpt`
  const id = resp.comment_id
  const comment = resp.comment
  const title = resp.title
  const commenter = resp.commenter
  const pov = resp.pov

  console.log("Title:", title)
  console.log("Commenter:", commenter)
  console.log("Comment:", comment)
  console.log("Reply:", reply)
  console.log("id:", id)


  const body = {
    "snippet": {
      "textOriginal": reply,
      "parentId": id
    }
  }
  try {
    const call = await fetch('https://www.googleapis.com/youtube/v3/comments?part=snippet', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${auth}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })

    const response = await call.json()
    if (response.error) {
      const skips = [400, 403]
      console.log("error", response.error.code)
      console.log("post_err", response.error.message)
      if (!(response.error.code in skips)) { logout() }

    } else {
      const { kind, etag, id, snippet } = response;
      const { textDisplay, textOriginal, parentId, authorDisplayName, authorProfileImageUrl, authorChannelUrl, authorChannelId, canRate, viewerRating, likeCount, publishedAt, updatedAt } = snippet;
      const { value } = authorChannelId;
      console.log("relpydata", authorDisplayName)
    }
  } catch (err) {
    console.log(err);
  }
  setPrompting(false)
}


export const testReply = async (auth) => {
  console.log(auth)

  const body = {
    "snippet": {
      "textOriginal": 'another new entrant',
      "parentId": "Ugw5V42xav0H6QbATPN4AaABAg"
    }
  }


  try {
    const call = await fetch('https://www.googleapis.com/youtube/v3/comments?part=snippet', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${auth}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })

    const response = await call.json()
    if (response.error) {
      console.log("test_err", response)
      //logout()
    } else {
      const { kind, etag, id, snippet } = response;
      const { textDisplay, textOriginal, parentId, authorDisplayName, authorProfileImageUrl, authorChannelUrl, authorChannelId, canRate, viewerRating, likeCount, publishedAt, updatedAt } = snippet;
      const { value } = authorChannelId;
      console.log("relpydata", authorDisplayName)
      // deleteReply(auth, id)
      return authorDisplayName
    }
  } catch (err) {
    console.log(err);
    return ''
  }
}

const deleteReply = async (auth, id) => {

  console.log("deleting", id)
  try {
    const call = await fetch(`https://www.googleapis.com/youtube/v3/comments?id=${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${auth}`,
        'Content-Type': 'application/json'
      }
    })

    const response = await call.json()
    console.log("deleted", response)
    if (response.error) {
      // logout()
    } else {
      console.log("deleted", id)
    }
  } catch (err) {
    console.log(err);
  }
}
